import Technologies from "../data/about.json";
import { useTranslation } from 'react-i18next';

function About() {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className="about" id="about">
            <h2>{t("menu.about_me")}</h2>
                <h3>
                    {t("about_description")}
                </h3>
                <div className="technologies">
                    {
                        Technologies && Technologies.map(record => {
                            return (
                                <div className="tech-icons" key={record.id}>
                                    <div className="border">
                                        <img src={record.img} alt={`Logo ${record.name}`} title={record.name} width="96" height="96"/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <a href="#contact" aria-label={t("contact")}>
                    <button className="contact-btn" aria-label={t("go_to_contact")}>{t("get_in_touch")}</button>
                </a>
            </div>
        </section>
    );
}

export default About;